<template>
  <div style="display: flex; flex-direction: row; gap: 20px">
    <h1 :hidden="isNotStart">
      <b-badge
        variant="light-warning"
        class="badge-timer"
      >
        {{ timerCount }}
      </b-badge>
    </h1>
  </div>
</template>

<script>

import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  data() {
    return {
      timerCount: '',
      timeStart: null,
      // timeActuDisplay: null,
      // timeStartDisplay: null,
      // timeExpectedDisplay: null,
    }
  },
  computed: {
    isNotStart() {
      return this.$store.getters['timer/getIsNotStart']
    },
  },
  watch: {
    isNotStart(value) {
      if (!value) {
        this.$store.dispatch('timer/getTimeStart').then(res => {
          this.timeStart = res
          this.timerCount = ' '
        })
      }
    },
    timerCount: {
      handler() {
        if (this.timeStart !== null) {
          setTimeout(() => {
            const now = new Date()
            const debut = this.timeStart
            const heureFin = debut + (30 * 60000) // Heure de debut + (30 * 60000) sec --> 30 minutes

            /*
            const deb = new Date(debut)
            this.timeActuDisplay = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
            this.timeStartDisplay = `${deb.getHours()}:${deb.getMinutes()}:${deb.getSeconds()}`

            const expected = new Date(heureFin)
            this.timeExpectedDisplay = `${expected.getHours()}:${expected.getMinutes()}:${expected.getSeconds()}`
            */

            const tempsRestant = heureFin - now
            let minutes = parseInt(tempsRestant / 60000, 10)
            let seconds = parseInt(((tempsRestant % 60000) / 1000), 10)
            minutes = minutes < 10 ? `0${minutes}` : minutes
            seconds = seconds < 10 ? `0${seconds}` : seconds
            this.timerCount = `${minutes}:${seconds}`
            if (tempsRestant <= 0) {
              this.timerCount = '00:00'
              this.$store.dispatch('timer/setTempsEcoule', true)
            }
          }, 1000)
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    if (this.isNotStart) {
      this.$store.dispatch('timer/getTimeStart').then(res => {
        this.timeStart = res
      })
    }
  },
}
</script>
<style lang="scss">
.badge-timer {
  min-width: 100px !important;
  font-size: 1em;
}
</style>
