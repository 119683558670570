import store from '@/store'
import admin from './admin'
import client from './client'

const currentUser = store.getters['auth/currentUser']

let naviguation = client

if (currentUser?.isAdmin) {
  naviguation = [
    ...naviguation,
    ...admin,

  ]
}

export default [
  ...naviguation,
]
