<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
      </div>
      <b-avatar
        size="30"
        variant="light-primary"
      />
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder">
          {{ userData.prenom }}  {{ userData.nom }}
        </p>
      </div>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="updatePassword"
    >
      <feather-icon
        size="16"
        icon="LockIcon"
        class="mr-50"
      />
      <span>Changer de mot de passe</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Se Deconnecter</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BNavItemDropdown,
    BDropdownItem,
  },
  data() {
    return {
      userData: this.$store.getters['auth/currentUser'],
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('token')

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    updatePassword() {
      const that = this
      this.$swal({
        title: 'Changement de mot de passe',
        html:
          '<input id="password" class="swal2-input" placeholder="Password">'
        + '<input id="password-confirm" class="swal2-input" placeholder="Password-confirm">'
        + '<span id="error" style="color:red;font-size:14px;"></span>',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          maxlength: 10,
          autocapitalize: 'off',
          autocorrect: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Confirmer',
        cancelButtonText: 'Annuler',
        showLoaderOnConfirm: true,
        preConfirm(data) {
          const password = document.getElementById('password').value
          const passwordconfirm = document.getElementById('password-confirm').value
          if (password !== passwordconfirm) {
            document.getElementById('error').textContent += 'Les deux mots de passe ne concordent pas!'
            return false
          }
          return password

          // if (!password) return null
        },

      })
        .then(result => {
          if (result.isConfirmed) {
            that.$store.dispatch('auth/sendNewPassword', { email: this.userData.email, password: result.value, token: localStorage.getItem('token') })
              .then(() => {
                that.$swal({
                  width: 500,
                  title: 'Changement confirmé',
                  text: 'Votre mot de passe a bien été changé. \n\n Vous allez être redirigié vers la page de connexion',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })

                this.logout()
              })
              .catch(() => {
                that.$swal({
                  width: 500,
                  title: 'Changement non effectué',
                  text: "Une erreur technique s'est produite. Veuillez contacter le support SQE Services",
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          }
        })
    },
  },
}
</script>

<style >
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-nav {
    margin-left: 0.8rem;
}

</style>
