<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center d-none d-lg-flex">
      <b-button
        class="btn-sm"
        variant="secondary"
        @click="showContactModal()"
      >
        <!-- <dark-Toggler class="d-none d-lg-block" /> -->
        Contactez-nous
      </b-button>
    </div>
    <div>
      <b-modal
        id="modal-contact"
        v-model="modalData.show"
        :title="modalData.title"
        ok-title="Envoyer"
        cancel-variant="outline-secondary"
        cancel-title="Annuler"
        size="lg"
        ok-only
        :hide-footer="true"
        centered
        @hidden="resetModale"
      >
        <validation-observer ref="observer">
          <b-form>
            <b-form-group
              label="Nom"
              label-for="nom"
            >
              <validation-provider
                #default="{ errors }"
                name="nom"
                rules="required"
              >
                <b-form-input
                  id="nom"
                  v-model="contactForm.nom"
                  placeholder="Nom"
                  name="nom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Prénom"
              label-for="prenom"
            >
              <validation-provider
                #default="{ errors }"
                name="prenom"
                rules="required"
              >
                <b-form-input
                  id="prenom"
                  v-model="contactForm.prenom"
                  placeholder="Prénom"
                  name="prenom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="E-mail"
              label-for="email"
              label-cols="0"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="contactForm.email"
                  placeholder="E-mail"
                  name="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Téléphone"
              label-for="telephone"
            >
              <b-form-input
                id="telephone"
                v-model="contactForm.telephone"
                placeholder="Téléphone"
              />
            </b-form-group>
            <b-form-group
              label="Objet de la demande"
              label-for="objet"
            >
              <validation-provider
                #default="{ errors }"
                name="objet"
                rules="required"
              >
                <b-form-input
                  id="objet"
                  v-model="contactForm.objet"
                  placeholder="Votre demande"
                  name="objet"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message"
              label-for="message"
            >
              <validation-provider
                #default="{ errors }"
                name="message"
                rules="required"
              >
                <b-form-textarea
                  id="message"
                  v-model="contactForm.message"
                  rows="6"
                  type="textarea"
                  name="message"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-flex float-right">
              <b-button
                class="mr-1 ml-1"
                variant="outline-secondary"
                @click="toggleModale()"
              >Annuler</b-button>
              <b-button
                class="mr-1 ml-1"
                variant="primary"
                @click="sendContactForm()"
              >Envoyer</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <Timer />
    </b-navbar-nav>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- MOCK BUTTON -->
      <!-- ====================== -->
      <!-- <div style="display: flex; gap: 5px">
        <b-button
          variant="primary"
          @click="onStart"
        >
          Start
        </b-button>
        <b-button
          variant="primary"
          @click="onNext"
        >
          Next question
        </b-button>
      </div> -->
      <!-- ====================== -->
      <div class="mx-1">
        Solde : {{ user.solde }} credit(s)
      </div>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton, BForm, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'

import {
  ValidationProvider, ValidationObserver, localize, validate,
} from 'vee-validate'

import fr from 'vee-validate/dist/locale/fr.json'
import Timer from '@/views/components/Timer.vue'
import UserDropdown from './navbar/userDropDown.vue'
import {
  required, email,
} from '@validations'
localize('fr', fr)

export default {
  components: {
    Timer,
    BLink,
    BNavbarNav,
    UserDropdown,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,

    // Navbar Components
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      email,
      // MOCK DATA
      // =================
      // =================
      modalData: {
        show: false,
        title: 'Formulaire de contact SQE Services',
      },
      contactForm: {
        email: '',
        objet: '',
        message: '',
        nom: '',
        prenom: '',
        telephone: '',
      },
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/currentUser']
    },
  },
  beforeMount() {
    this.contactForm.email = this.user.email
    this.contactForm.nom = this.user.nom
    this.contactForm.prenom = this.user.prenom
  },
  methods: {
    onStart() {
      this.$store.dispatch('timer/onStart')
    },
    showContactModal() {
      this.modalData.show = true
    },
    resetModale() {
      this.contactForm.objet = ''
      this.contactForm.message = ''
      this.contactForm.nom = ''
      this.contactForm.prenom = ''
      this.contactForm.telephone = ''
    },
    toggleModale() {
      this.modalData.show = !this.modalData.show
      this.resetModale()
    },
    sendContactForm() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          this.$store.dispatch('auth/sendContactForm', this.contactForm).then(
            () => {
              this.contactForm = {
                email: this.user.email,
                subject: '',
                messageBody: '',
                nom: this.user.nom,
                prenom: this.user.prenom,
                telephone: '',
              }
              this.modalData.show = false
              this.$swal({
                title: 'Message envoyé',
                text: 'Votre message a bien été envoyé aux équipes SQE Services',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            },
            () => {
              this.modalData.show = false
              this.$swal({
                title: "Problème d'envoi",
                text: "Un problème technique a fait echoué l'envoi de votre message. \n Veuillez rééssayer ulterieurement.",
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            },
          )
        }
      })
    },
  },
}
</script>
